@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

 *,html{
    box-sizing: border-box;
    margin:0px;
    padding:0px;
    text-decoration: unset !important;
    outline: none;
    list-style: none;
    font-family: 'Poppins', sans-serif !important;
 }

:root{
  --red:#f31717;
  --white:#ffff;
  --bluedark:#000625;
  --white-smoke:#F1F2F6;
  --purple:#733084;
}

body .bg-blue{
    background-color:  var(--bluedark)!important;
}

body .wrap-box{
   display: flex;
   align-items: center;
   flex-wrap: wrap;
}

body .wrap-box .dot{
    width:6px;
    height:6px;
    border-radius:100%;
    margin:0px 10px;
    background: var(--red);
}

body .apbc-img-wrapper{
  height:330px !important;
  overflow: hidden;
}

body .offcanvas{ 
  z-index:20000 !important; 
}

body .menu-button{
   display: none;
}

body .offcanvas .cover{
  width:100%;
  height:50%;
 background-color: red;
 position: absolute;
 top:0px;
 left:0px;
 z-index:1;
 background-image:url("./Assets/img/moscas.png"); 
 background-repeat: no-repeat;
}

body .offcanvas .cover.purple{
    background:var(--purple) !important;
}

body .offcanvas .canvas-body{
    z-index:1000;
    position: relative;
}

body .offcanvas .cover.purple{
  background-color:var(--purple);
  background-image:url("./Assets/img/moscas.png") !important;    
}


body .space{
  display: flex;
  justify-content: space-between;
  width:100%;
}

body .wa-chat-box-brand{
   background-color: #ffff;
}

body .wa-chat-box-poweredby{
  display:none !important;
}

body .apbc-scroll-top{ 
  box-shadow:0px 3px 10px rgba(0,0,0, 0.3) !important;
}

body #wa-widget-send-button,
body .wa-chat-box{
  right:90px !important;
  left: unset !important; 
  z-index:100 !important;
}

body #wa-widget-send-button{
  bottom:-20px !important;
}

body .offcanvas{
  padding:10px;
}

body .offcanvas .btn.close{
  height:30px;
  width:30px;
  float: right;
  font-size:20px;
  display: flex;
  align-items: center;
  padding:0px;
  justify-content: center;
}

body .offcanvas .apbc-pera-txt p.red{
   color:var(--red) !important;
   font-size:20px;
   margin-bottom:10px;
}

body .offcanvas .apbc-pera-txt p.purple{
  color:var(--purple) !important;
  font-size:20px;
  margin-bottom:10px;
}

body .offcanvas label{
   color:#000;
   font-weight:600;
}

body .offcanvas  span p{
   font-weight:bolder;
}

body .offcanvas .card-box{
     border-radius:10px;
     padding:20px;
     background:#ffff;
     box-shadow:0px 3px 10px rgba(70, 73, 95, 0.2);
}

body .mr-2{
  margin-right:15px;
}

body .apbc-about-section,
body .apbc-project-section{
  padding-bottom:0px !important;
}

 body .wrapper{
      width:100%;
      margin:0 auto !important;
      max-width: 1240px;
      height: auto; 
      font-family: 'Poppins', sans-serif !important;
 }

body .badge.bg-success{
    color: rgb(7, 97, 7) !important;
    background: rgb(198, 255, 198) !important;
}

body .badge.bg-warning{
  color: orange !important;
  background: rgb(255, 239, 198) !important;
}

body .badge.bg-danger{
    color: rgb(146, 0, 25) !important;
    background: rgb(255, 193, 194) !important;
}

body .btn{
  transition: all 1s ease-in-out; 
}

body .small-title{
    font-size:18px;
    font-weight: 600;
    letter-spacing:1px;
}


body .btn.bg-primary.btn-icon{
   display: flex;
   align-items: center;
   color:var(--white);
   padding:8px 15px !important;
   border:2px solid var(--red);
}

body .btn.bg-primary.btn-icon:hover{
    background: var(--bluedark) !important;
}

body .btn.bg-primary.btn-icon:hover svg{
   fill:var(--bluedark) !important;
}

body .btn.bg-primary.btn-icon .icon{
  background:var(--white);
  width:40px;
  height:40px;
  margin-left:15px;
  border-radius:100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


body .pd{
   padding:0px 10px;
}
 

body .btn.bg-primary.btn-icon svg{
    fill:var(--red);
}


body .large-title{
    font-size:57px !important; 
    color:var(--white);
    letter-spacing:1px; 
    font-weight:bolder !important; 
    line-height: 1.25;
    letter-spacing: -1px;
}

body .large-title span{
    color:var(--red);
}
 
body .fixed-navbar{
     background:var(--bluedark);
     color:var(--white);
     position: fixed;
     top:0px;
     left:0px;
     width:100%;
     border: none !important;
     -webkit-box-shadow: -3.119px 14.672px 60px 0px rgba(70, 73, 95, 0.6);
     box-shadow: -3.119px 14.672px 60px 0px rgba(70, 73, 95, 0.6); 
}

body .mediumn-title{
   font-size:30px !important; 
    letter-spacing:1px; 
    font-weight: 700;
    line-height: 1.25; 
    color:var(--bluedark);
}

body .mediumn-title span{
  color:var(--red);
}

body .btn{
  font-weight:590;
}

 #range-slider {
  background: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23555' fill-rule='evenodd'%3E%3Cpath d='M0 40L40 0H20L0 20M40 40V20L20 40'/%3E%3C/g%3E%3C/svg%3E") #333;
}

#range-slider .range-slider__range {
  background: var(--red);
  transition: height .3s;
}

#range-slider .range-slider__thumb {
  background: var(--red);
  transition: transform .3s;
}

#range-slider .range-slider__thumb[data-active] {
  transform: translate(-50%, -50%) scale(1.25);
}

#range-slider .range-slider__range[data-active] {
  height: 16px;
}

body .btn{
    padding:10px 15px;
}

body .bg-primary{
  background:var(--red) !important;
}

body .btn-circle{
  border-radius:30px !important;
}

body .paginate{
  width:100%; 
  display: flex;
  justify-content: flex-end;
  margin:20px 0px;;
}

body .paginate ul{
  display: flex;
  align-items: center;  
  flex-wrap: wrap; 
  width: max-content;
  justify-content: flex-end;
  max-width:900px; 
  margin-bottom:0px;
  padding:8px 20px !important; 
}

body .paginate  ul li{
  padding:5px;
  margin-right:15px;
  width:34px;
  height:34px; 
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius:100%;
  color:var(--bluedark);
}

body .paginate  ul li.previous,
body .paginate  ul li.next{
   width:34px;
   height:34px; 
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius:100%;
   background:var(--bluedark); 
}


body .paginate  ul li.selected{
  background:var(--red);
  color:#fff;
}

body .paginate  ul li.next{
margin-right:0px !important;
}

body .paginate  ul li.previous a,
body .paginate  ul li.next a{
color:#fff;
text-decoration: none;
}

body .slick-dots li{
   width:20px;
   height:20px;  
   text-align: center;
   border:2px solid var(--bluedark);
   position: relative;
   border-radius:100%;
}

 

body .slick-dots li::after{
  content:'';
  width:8px;
  height:8px;
  position: absolute;
  left:4px;
  top:4px;
  background-color: var(--white-smoke);
  border:1px solid var(--bluedark);
  border-radius:100%;
}

body .slick-dots li.slick-active{
  border-color:var(--red) !important;
}

body .slick-dots li button{
  display: none;
}

body .slick-dots li.slick-active::after{
  background: red;
  border-color:var(--red);
}

body .slick-list{
  padding-bottom:30px !important;
}


body .apbc-service-section{
  padding:40px 0px !important;
  max-height:950px !important; 
} 


body .apbc-service-section .slick-dots{ 
  bottom:10px !important;
}

body .apbc-team-carousel{
  display: flex; 
  justify-content: space-between;
}

body .apbc-team-carousel .apbc-team-single{
   min-width:24%;
   max-width:24.5%; 
   overflow: hidden;
}


body  #mov_planos .apbc-service-slider{
  min-height:750px !important; 
}


body  #mov_planos  .apbc-service-single{
  min-height:640px !important;
  background:#ffffff; 
} 


body  #mov_planos  .apbc-service-single a.d-flex .icon{
  margin-left:10px;
}

body .apbc-service-section {
  padding:0px !important;
}

body .apbc-service-section .slick-list{
   max-height:700px !important;
}
 
.apbc-client-feedback .slick-list{ 
    max-height:350px;
}

body .slick-dots li{
  width:10px;
  height:10px;
  min-width:10px !important;
  min-height:10px !important;
  border-radius:0px !important;
  background-color: var(--bluedark);
  margin:0px 5px !important;
  border-radius:2px;
}

body .slick-dots li.slick-active{
  background: var(--red) !important;
}

body .slick-dots li::before,
body .slick-dots li::after{
  display: none !important;
}

body .ml-2{
  margin-left:10px !important;
}

body .apbc-slide-left{ 
  z-index:1000 !important; 
  position: relative;
}

body .icr svg{
  fill: var(--red);
  margin-right:10px;
  color:var(--red);
}
 
body .apbc-about-inner h6{
  display: flex;
  align-items: center;
}

.apbc-hero-slider .slick-list{
  padding: 0px !important;
}

body  .center-data .apbc-pera-txt p{
    color: var(--red);
    font-size:20px;
    margin-bottom:20px;
}

body .apbc_sidebar_info_content .apbc_sidebar_logo{
    display: flex;
    align-items: center;
    justify-content: center; 
    width:100%;
    margin-bottom:20px;
}


body .apbc_sidebar_info_content img{
  max-width:150px;
  margin: 0 auto;
}

body a.bg-bl{
   color:var(--bluedark);
}

body .apbc-readmore-btn a{
   color:var(--bluedark);
}

body .apbc-readmore-btn:hover a,
body .apbc-readmore-btn:hover svg{
   fill:var(--red) !important;
   color:var(--red) !important;
}

body .apbc-video-right svg,
body .apbc-readmore-btn svg{
  fill: var(--bluedark);
}


body .apbc-video-right a{
    cursor: pointer;
}

body .apbc-video-right a svg{
   fill:var(--red) !important;
}


body .apbc-video-right:hover a svg{
    fill:#ffff !important;
}

body .apbc-img-wrapper{
  background:#E7E9EF;
  min-height:300px; 
  padding:0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

body .apbc-img-wrapper img{ 
  height:100%;
  min-height:100%;
}

body #mov_planos .apbc-service-single{
  min-height:600px;
}

body .apbc-service-single img{
   height:100%;
}

.apbc-primary-btn .bg-primary .icon,
.apbc-secondary-btn .bg-primary .icon{
    width:40px;
    min-width:40px;
    height:40px;
    border-radius:100%;
    margin-left:10px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background:var(--white);
}

.apbc-primary-btn .bg-primary .icon svg,
.apbc-secondary-btn .bg-primary .icon svg{
  fill:var(--red);
}

.apbc-primary-btn .bg-primary:hover,
.apbc-secondary-btn .bg-primary:hover{
  background: var(--bluedark) !important;
}

.apbc-primary-btn .bg-primary:hover svg,
.apbc-secondary-btn .bg-primary:hover svg{
   fill:var(--bluedark);
}

body a.bg-bl{
    color:var(--white);
    background: var(--bluedark) !important;
}
 
body a.bg-bl .icon{
    width:40px;
    min-width:40px;
    height:40px;
    border-radius:100%;
    margin-left:10px !important;
    display: flex;
    align-items: center;
    justify-content: center; 
    background:var(--white);
}
 
body a.bg-bl .icon svg{
  fill:var(--bluedark);
}
 
body a.bg-bl:hover{
  background: var(--red) !important;
}
 

body a.bg-bl:hover svg{
   fill:var(--red);
}


body .apbc-slide-shape-1,
body .apbc-slide-shape-2{
  display: none !important;
}

body .wa-widget-send-button{
  display: none !important;
}

body .apbc-nav-menu ul{
  padding-left:0px !important;
}

body .apbc-project-section a svg{
  fill:var(--white);
}

body .apbc-pr-column img{
  max-height:300px;
}


/*Purple area **/

body .purple-area .bg-primary ,
body .purple-area .apbc-sidebar-btn a,
body .purple-area .apbc-primary-btn a,
body .purple-area  .apbc-nav-menu ul li a::after,
body .purple-area  .apbc-nav-menu ul li a::before{
    background:var(--purple) !important;
    border-color:var(--purple);
}
body .purple-area .apbc-primary-btn  .icon svg{
    fill: var(--purple);
}

body .purple-area .footer-section .icon.sc.active svg{
     fill: var(--purple) !important;
}


body .purple-area .footer-section  ul li:hover,
body .purple-area .footer-section  ul li:hover a,
body .purple-area .footer-section  ul li:hover svg{
    color: var(--purple) !important;
    fill: var(--purple) !important;
}

body .purple-area .dropdown-menu{
  border-color:var(--purple) !important;
}

body .apbc-header-section{
  max-height:90px !important;
  height:90px;
  display: flex;
  align-items: center;
  justify-content: center; 
}

body .apbc-header-section a img{
  max-height:80px;
}