 @media screen and (max-width:900px) {
    body .apbc-team-carousel {
        flex-direction: column !important;
        width: 100% !important; 
    }
    
    
    body .apbc-team-carousel .apbc-team-single{
         width: 100% !important;
         max-width: 100% !important;
         min-width: 100% !important;
         background-color: #ffffff;
    }
    
    
    body .apbc-slide-left{
        text-align: left !important;
        align-items: flex-start;
    }
    
    body .apbc-slide-left h1{
        margin-top: 50px;
    }
    
    
    body .apbc-single-item img{
       display: none !important;
    }
 
    body .apbc-mobile-menu-open svg{
        width:30px !important;
        height:30px !important;
        font-size:10px !important;
    }
    

    body .apbc-single-item{
         background-image: url("./Assets/images/shape.png") !important;
         max-height:400px !important;
         min-height:660px !important;
    }

    body .menu-button{
          width:50px;
          height:50px;
          border-radius:100%;
          display: flex !important;
          align-items: center;
          justify-content: center;
          margin-left:10px;
          min-width:50px; 
          color:var(--white);
          font-size:30px;
    }

    body .menu-button svg{
         color:var(--white);
    }

    body .copyright{
         padding:0px 20px;
    }
 

    body .video-frame iframe{
          min-width:100% !important;
          width:100% !important;
          min-height:200px;
          height:200px;
    }

    body .footer-section{
       flex-direction: column;
    }

    body .copyright img{
       max-width:130px !important;
    }
    
 }

















